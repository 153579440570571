<template>
  <div class="wrap">
    <Navbar :title="$t('page.games')" :leftArrow="false" />
    <!-- <keep-alive> -->
    <div class="nav-warp">
      <div class="nav_item" v-for="(item, index) in navs" :key="index" :class="index === navNow && 'item_active'"
        @click="navChange(item, index)">
        {{ item.title }}
      </div>
    </div>
    <!-- </keep-alive> -->
    <!-- <div v-show="false">{{ merchants }}</div> -->
    <div class="merchants_wrap">
      <div class="merchants_item"
        v-for="(item, index) in navs[navNow].value === 'all' ? merchants : merchants.filter(i => i.time_rand === navs[navNow].value)"
        :key="index" @click="toGame(item)">
        <!-- :style="{ backgroundImage: `url(${require('@tt/assets/home/games/item_bg.png')})` }" -->
        <div class="content_wrap">
          <img :src="item.picture" alt="" class="left" />
          <div class="right">
            <div class="title">{{ item.title }}</div>
            <div class="subtitle">{{ item.subtitle }}</div>
            <div class="time">{{ item.countTime }}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- <Tabbar active="make-money" /> -->
  </div>
</template>

<script>
import Tabbar from "@/components/Tabbar";
import { $post } from "@/utils/request";
import { cookie } from "../../utils";
import Navbar from "@/components/JNav";
export default {
  components: {
    Tabbar,
    Navbar,
  },
  data() {
    this.navs = [
      { title: this.$t("merchant.all"), value: "all" },
      { title: this.$t("merchant.long"), value: "long" },
      { title: this.$t("merchant.short"), value: "short" },
      // { title: this.$t("merchant.hot"), value: "hot" },
      // { title: this.$t("merchant.list"), value: "all" },
    ];
    return {
      navNow: 0,
      merchants: [],
      interval: null,
      getDataInterval: null,
    };
  },
  created() {
    this.$store.dispatch("getGameList", { type: this.navs[this.navNow].value });
    this.getMerchants();
    // if (this.merchants.length) {
    // }
  },
  computed: {
    gameList() {
      // const type = this.navs[this.navNow].value;
      // switch (type) {
      //   case "all":
      //     return this.$store.getters.AllGameList;
      //   case "hot":
      //     return this.$store.getters.HotGameList;
      //   default:
      //     break;
      // }
      return this.$store.getters.AllGameList;
    },
  },
  watch: {
    gameList(val) {
      if (val.length) {
        this.getMerchants();
      }
    },
  },
  methods: {
    navChange(item, index) {
      this.navNow = index;
      // this.getMerchants();
      // this.$store.dispatch("getGameList", { type: item.value });
    },
    // 获取商家(玩法)数据
    async getMerchants() {
      try {
        // const res = await $post("/lottery/setting", {
        //   merchant_type: this.navs[this.navNow].value,
        //   lastinfo: 1,
        // });
        // const { ret, msg, data } = res.data;
        const type = this.navs[this.navNow].value;
        const data = this.gameList;
        if (!!this.interval) {
          clearInterval(this.interval);
        }
        let time = 0;
        this.merchants = this.dataInit(data);
        this.interval = setInterval(() => {
          time++;
          this.merchants = this.dataInit(data, time);
        }, 1000);
      } catch (error) {
        console.error(error);
      }
    },
    dataInit(data, time = 0) {
      const arr = [...data];
      for (let i = 0, len = arr.length; i < len; i++) {
        const item = data[i];
        try {
          if (item.currentinfo.countdown - time >= 0) {
            item.countTime = this.countFormat(
              item.currentinfo.countdown - time,
              item
            );
          }
        } catch (error) {
          item.countTime = this.$t("status.open");
        }
      }
      return arr;
    },
    countFormat(num, data) {
      if (num <= 0) {
        const { opentime, endtime } = data.currentinfo;
        this.getDataInterval = setTimeout(() => {
          // this.getMerchants();
          this.$store.dispatch("getGameList", { type: this.navs[this.navNow].value })
        }, (opentime - endtime) * 1000);
        return this.$t("status.open");
      }
      const hourUnit = 60 * 60;
      const minuteUnit = 60;
      const secondUnit = 1;
      const targetH = Math.floor(num / hourUnit);
      const targetM = Math.floor((num % hourUnit) / minuteUnit);
      const targetS = Math.floor(((num % hourUnit) % minuteUnit) / secondUnit);
      return `${this.format(targetH)}:${this.format(targetM)}:${this.format(
        targetS
      )}`;
    },
    format(num) {
      if (num >= 10) return num;
      else return "0" + num;
    },
    // 进入玩法
    toGame(item) {
      const token = cookie.get("token");
      if (!!token) this.$router.push(`/Game?type=${item.type}`);
      else this.$router.push("/login");
    },
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
};
</script>

<style lang="less" scoped>
@wrapPadding: calc(12rem / 16);
@comRadius: 4px;
@comBG: linear-gradient(#093958, #022c47);

.wrap {
  @wrap_gap: @wrapPadding;
  // padding: @wrap_gap;
  // padding-top: calc(10rem / 16);
  background: var(--hallBg);
  // min-height: calc(100vh - 50px);
  min-height: 100vh;

  .nav-warp {
    display: flex;
    justify-content: space-between;
    margin-bottom: calc(10rem / 16);
    padding: @wrap_gap @wrap_gap 0;

    .nav_item {
      @height: calc(40rem / 16);
      width: 33%;
      color: var(--textColor);
      text-align: center;
      height: @height;
      border-radius: @comRadius;
      overflow: hidden;
      // background-image: linear-gradient(to bottom right, #8da5ce, #46c0f9);
      color: var(--theme);
      line-height: @height;
    }

    .item_active {
      // background-image: linear-gradient(to bottom right, #fae64f, #f79883);
      background: var(--theme);
      // color: var(--textColor);
      color: var(--light);
    }
  }

  .merchants_wrap {
    @merchants_gap: calc(10rem / 16);
    padding: 0 @wrap_gap;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .merchants_item {
      @imageSize: calc(60rem / 16);
      width: calc(50% - (@merchants_gap / 2));
      // height: calc(@imageSize + calc(20rem / 16));
      // box-shadow: 0 0 5px 2px #849eca;
      border-radius: 4px;
      // background: #fff;
      margin-bottom: @merchants_gap;
      background: linear-gradient(#093958, #022c47);
      margin-right: @merchants_gap;

      &:nth-of-type(2n) {
        margin-right: 0;
      }

      &:nth-last-of-type(1),
      &:nth-last-of-type(2) {
        // margin-bottom: 0;
      }

      .content_wrap {
        padding: calc(10rem / 16) calc(5rem / 16);
        display: flex;
        // background: linear-gradient(#093958, #022c47);

        .left {
          width: @imageSize;
          height: @imageSize;
        }

        .right {
          margin-left: calc(10rem / 16);
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          // color: #666;
          // color: #00bbfe;
          color: var(--theme);

          .title {
            font-size: calc(14rem / 16);
          }

          .subtitle {
            font-size: calc(12rem / 16);
          }

          .time {
            font-size: calc(14rem / 16);
          }
        }
      }
    }
  }
}
</style>
